$(function() {

    const $window = $(window);
    const winW = $window.width();

    $('.nav_li, .func_li').hover(function() {
        $(this).addClass('show');
    }, function() {
        $(this).removeClass('show');
    });
    $('.nav_li, .func_li').focusin(function() {
        $(this).addClass('show');
    });
    $('.nav_li, .func_li').focusout(function() {
        $(this).removeClass('show');
    });


    //menu選單 手機板-------------------------------------------
    if (winW <= 950) {

        // $('.nav_hide').children('.subnav_box').remove();

        const header = $('.header');
        const hamburger = $('.hamburger'),
            nav = $('.nav'),
            nav_link = $('.nav_link'),
            subnav_box = nav.find('.subnav_box'),
            navReset = function() {
                nav_link.attr('o', 1);
                nav.clearQueue();
                subnav_box.slideUp(0);
            };
        let o = true;

        TweenMax.set(nav, {
            opacity: 0,
            visibility: 'hidden',
            display: 'none',
        });
        hamburger.click(function() {
            if (o) {
                if (winW <= 950) navReset();
                TweenMax.to(nav, .5, { opacity: 1, visibility: 'visible', display: 'block' });
                header.addClass('show');
                o = false;
            } else {
                TweenMax.to(nav, .5, { opacity: 0, visibility: 'hidden', display: 'none' });
                header.removeClass('show');
                o = true;
            }
        });

        navReset();
        nav_link.click(function(e) {
            var _this = $(this),
                _thisSubnav = _this.siblings('.subnav_box'),
                _o = _this.attr('o');
            if (_thisSubnav.length <= 0) return;
            e.preventDefault();
            if (_o == 1) {
                _thisSubnav.clearQueue();
                _thisSubnav.slideDown();
                _this.attr('o', 0);
            } else {
                _thisSubnav.slideUp();
                _this.attr('o', 1);
            }
        });

    }
});