$(function() {
    var trackerDotW, mouseX, mouseY, trackerW;
    $("body").bind("mousemove", function(a) {
        mouseX = a.clientX;
        mouseY = a.clientY;
        trackerW = $(".tracker").width();
        TweenMax.to('.tracker', .3, { x: mouseX - trackerW / 2, y: mouseY - trackerW / 2, opacity: 1, repeat: 0, delay: .05 })
    });
    $('.hover').hover(function() {
        $(".tracker").addClass('hover');
    }, function() {
        $(".tracker").removeClass('hover');
    });
});