$(function() {

    let _this;
    let _c;
    const active_li = $('.active');
    const collapse = $('.collapse');
    const sidenav = $('.sidenav');
    const sidenav_link = $('.sidenav_link');
    const li = collapse.children('.sidenav_link');
    const sidenav_box = collapse.children('.sidenav');
    const active_box = active_li.children('.sidenav');
    li.attr('c', 0);
    sidenav_box.slideUp(0);
    active_box.slideDown(0);
    active_li.children('.sidenav_link').attr('c', 1);
    li.click(function(e) {
        if ($(this).hasClass('_collapse')) {
            e.preventDefault();
        }
        _this = $(this);
        _c = _this.attr('c');
        if (_c == 0) {
            _this.siblings('.sidenav').slideDown();
            // _this.parent('.sidenav_li').addClass('active');
            _this.attr('c', 1);
        } else if (_c == 1) {
            _this.siblings('.sidenav').slideUp();
            // _this.parent('.sidenav_li').removeClass('active');
            _this.attr('c', 0);
        }
    });
});