import '../../css/share/common.scss';
import './layout/_header';
import './layout/_sidenav';
import './components/_tracker';
import './components/_ck_editor';
import { lazyload } from "./components/_lazyload.js";

$(function() {

    let _href;
    //a[href*="#"]
    $('a[href*="#"]').click(function(e) {
        e.preventDefault();
        _href = $(this).attr('href');
        if (_href == '#AC') {
            $('#AC').focus();
            TweenMax.to('html, body', .7, { scrollTop: $('#AC').offset().top, ease: Quart.easeOut });
        }
    });

    //fastlink
    if ($(".fastlink").length > 0) {

        const fastlink = $('.fastlink');
        const fastlink_tab = $('.fastlink_tab');
        const fastlink_row = $('.fastlink_row');
        const fastlink_w = fastlink_row.outerWidth(true);
        let _fl = true;

        TweenLite.set(fastlink, { x: fastlink_w });
        fastlink_tab.click(function() {
            if (_fl) {
                TweenLite.to(fastlink, .3, { x: 0, ease: Power2.easeOut });
                _fl = false;
            } else {
                TweenLite.to(fastlink, .3, { x: fastlink_w, ease: Power2.easeOut });
                _fl = true;
            }
        });
        fastlink.focusin(function() {
            TweenLite.to(fastlink, .3, { x: 0, ease: Power2.easeOut });
            _fl = false;
        });
        $('a').not('.fastlink_link').focus(function() {
            TweenLite.to(fastlink, .3, { x: fastlink_w, ease: Power2.easeOut });
            _fl = true;
        });
    }

    //swiper
    if ($(".sponsor_swiper").length > 0) {

        const $swiper = '.sponsor_swiper';
        const container = $swiper + ' .swiper';
        const pagination = $swiper + ' .swiper-pagination';
        const button_next = $swiper + ' .swiper-button-next';
        const button_prev = $swiper + ' .swiper-button-prev';

        const mySwiper = new Swiper(container, {
            grabCursor: true,
            slidesPerView: 'auto',
            slidesPerGroupAuto: true,
            slidesPerGroup: 2,
            loop: true,
            speed: 600,
            autoplay: {
                delay: 2000,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            navigation: {
                nextEl: button_next,
                prevEl: button_prev,
            },
            a11y: {
                enabled: true,
                firstSlideMessage: "第一則",
                lastSlideMessage: "最後一則",
                paginationBulletMessage: "到第 {{index}} 則",
            },
        });
    }

    //--------------------- imagesLoaded ---------------------//
    function removeLoading() {
        $(".loading").fadeOut(function() {
            $(this).remove();
        });
    }

    imagesLoaded("html, body", function() {
        removeLoading();
        lazyload();
    });
});